<template>
    <div>
        <div class="cards" v-for="activity in activities" :key="activity.id">
            <div class="title">{{ activity.title }}</div>
            <div class="content">{{ activity.content }}</div>
            <div class="date">{{ activity.date }}</div>
        </div>
        <br />
        <el-pagination
                v-if="page_total"
                background
                layout="prev, pager, next"
                :total="page_total"
                @prev-click="prevPage"
                @next-click="nextPage"
                @current-change="handleCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import api from '@/api';

export default {
    data() {
        return {
            activities:[],
            // 總頁數
            page_total:0,
            // 當前頁數
            antorbital_page:1,
            // 每頁條數
            page_number:5,
            fundraise_id: undefined,
            i:0,
            j:0,
        };
    },
    methods:{
        handleCurrentChange(Page){
            this.i = 1;
            this.j = 0;
            this.antorbital_page = Page;
            this.contents_list();
        },
        prevPage(){
            this.j = 1;
            this.antorbital_page-=1;
            this.contents_list();
        },
        nextPage(){
            this.j = 1;
            this.antorbital_page+=1;
            this.contents_list();
        },
        contents_list(){
            if((this.j != 1 && this.i != 1) || (this.j == 0 || this.i == 0)){
                api.fundraiseDynamic.getFundraiseDynamic('?fundraise='+this.fundraise_id+'&status=1&pageNum='+this.antorbital_page+'&pageSize='+this.page_number).then(response => {
                    if(response.code == 200){
                        let content_list = response.data.results;

                        this.page_total = Math.ceil(response.data.count / this.page_number) * 10;


                        for(let i=0; i<content_list.length; i++){
                            let time = new Date(content_list[i].createDatetime);
                            var Y = time.getFullYear()
                            var M = (time.getMonth()+1 < 10 ? '0'+(time.getMonth()+1) : time.getMonth()+1)
                            var D = time.getDate()

                            content_list[i].date = Y+'年'+M+'月'+D+'日';
                        }

                        this.activities = content_list;
                    }else{
                        this.$message.error(response.msg);
                    }
                }).catch(error=>{
                    this.$message.error('數據異常，請重新獲取！');
                });
            }
        }
    },
    created(){
        let id = this.$route.params.id;
        api.fundraise.getFundraiseDetails(id).then(response => {
            this.fundraise_id =response.data.id;
            this.contents_list();
        }).catch(error=>{
            this.$message.error('基本資料數據異常，請重新獲取！');
        });
    }
};
</script>

<style lang="scss" scoped>
.cards {
    padding: 16px;
    background-color: $green-100;
    margin-bottom: 16px;
    @include fc-s-st;
    .title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 8px;
    }
    .content {
        line-height: 1.2;
        margin-bottom: 8px;
    }
    .date {
        margin-left: auto;
    }
}
</style>